/**
 *
 * jQuery Events:
 * – onEventsInit
 * – onEventsInited
 * – onEventsSubmit
 * – onEventsEdit
 *
 */

function ScheduledEvents(options = {}) {

  const editModal = $("#event-form");
  const ticketModal = $("#ticket-form");

  const commonControllerUrl = "/plugins/shows/common-controller.jsp";
  const showControllerUrl = "/plugins/shows/show-controller.jsp";
  const appointmentsControllerUrl = "/plugins/shows/appointment-controller.jsp";
  const calendarControllerUrl = "/plugins/shows/calendar-controller.jsp";

  const _this = this;

  const actions = {
    create_show: 'create-show',
    edit_event: 'edit-event',
    request_appointment: 'request-appointment',
    buy_ticket: 'buy-ticket'
  };

  const usedAMPM = options.usedAMPM !== false;
  const dateFormat = options.dateFormat || 'mm/dd/yy';
  const useCalendarTime = options.useCalendarTime !== false;
  const isDebug = options.debug;
  const useLinks = options.useLinks === true;

  this.appointmentRate = options.appointmentRate || 0;

  let action;

  function setAction(act) {
    action = act;
    editModal.find('input[name=cmd]').val(action);
  }

  this.initDatetimepicker = function (performerId) {
    const datepicker = $('#datepicker');

    let timeObj = {};

    if (useCalendarTime) {
      $('#time-picker').prop('disabled', true);
      $('#duration-field select').prop('disabled', true);
    }

    const calendarReq = {
      cmd: 'get-calendar',
      performerId: performerId
    };

    $.post(calendarControllerUrl, calendarReq, null, 'json')
      .done(calendarResponse => {
        const {message, code} = calendarResponse;
        const calendar = code !== 'ERR' ? JSON.parse(message) : [];
        timeObj = parseAvailableTime(calendar);
        showCalendarWithAvailableDates(datepicker, dateFormat, timeObj);
      });

    function parseAvailableTime(calendarArray) {
      const model = {};
      for (const item of calendarArray) {
        if (item === null) {
          continue;
        }

        const newTime = {
          startHour: item['startLocalTime'],
          endHour: item['endLocalTime']
        };

        if (model.hasOwnProperty(item.dayOfWeek)) {
          model[item.dayOfWeek].push(newTime);
        } else {
          model[item.dayOfWeek] = [newTime];
        }

      }
      return model;
    }

  };

  function showCalendarWithAvailableDates(datepicker, dateFormat, timeObj) {

    const today = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => I18n.t(day));
    const allowedDaysOfWeek = Object.keys(timeObj);

    datepicker.datepicker('destroy');
    datepicker.datepicker({
      inline: true,
      altField: '#event-calendar',
      altFormat: dateFormat,
      dateFormat: dateFormat,
      defaultDate: today,
      minDate: today,
      maxDate: '+6m',
      beforeShowDay: function (date) {
        const day = date.getDay();
        const dayName = days[day].toUpperCase();

        if (useCalendarTime) {
          if (allowedDaysOfWeek.length === 0) {
            return [true];
          } else {
            return [allowedDaysOfWeek.includes(dayName)];
          }
        } else {
          return [true];
        }
      },
      onSelect: function () {
        const date = datepicker.datepicker('getDate');
        const day = date.getDay();
        const dayName = days[day].toUpperCase();

        if (useCalendarTime) {
          $('#time-picker').prop('disabled', false);
          $('#duration-field select').prop('disabled', false);
        }
        showTimepickerWithAvailableTime($('#time-picker'), timeObj, dayName);
        _this.updateSummary();
      }
    });

    $('.ui-datepicker-current-day').click();
  }

  function showTimepickerWithAvailableTime(timepickerInput, timeObj, dayName) {
    const availableTimeRangesForSelectedDay = function() {
      let utr = [];

      if (!timeObj || Object.keys(timeObj).length === 0 || !useCalendarTime) {
        return utr;
      }

      const availableTimeArray = timeObj[dayName];

      if (timeObj[dayName]) {
        for (let entry of availableTimeArray) {
          let startHour = entry.startHour.hour;
          let startMinute = entry.startHour.minute;

          let endHour = entry.endHour.hour;
          let endMinute = entry.endHour.minute;

          if (startHour === endHour && startMinute === endMinute) {
            endHour++;
          }

          utr.push({
            startTime: [startHour, startMinute],
            endTime: [endHour, endMinute]
          });
        }
      }

      return utr;
    }();

    timepickerInput.timedropdown({
      availableRanges: availableTimeRangesForSelectedDay,
      interval: 20,
      ampm: usedAMPM
    });

    timepickerInput.on('changeTime', function () {
      _this.updateSummary();
    });
  }

  this.updateSummary = function() {
    debug('updateSummary()');

    if (this.appointmentRate && this.appointmentRate !== 0) {

      let startDate = $('#event-calendar').val();
      let startTime = $('#time-picker').val();

      let duration = $('*[name=duration_min]').val();
      let creditsRequired = Math.floor(duration * this.appointmentRate);

      if (startTime != null) {
        $('#appointment-startTime').html('<b>' + startDate + ' ' + startTime + '</b>');
      }

      if (duration != null) {
        $('#appointment-credits').html(
            I18n.t('You must have: ') + '<b>' + creditsRequired + '</b> ' + I18n.t('credits'));
        $('#appointment-duration').html('<b>' + duration + ' ' + I18n.t('min.') + '</b>');
      }

    }
  };

  /**
   * @deprecated
   */
  this.init = function() {/*backward compat func*/};

  (function init() {
    $(document).trigger('onEventsInit', _this);

    debug('init');

    _this.initDatetimepicker($('input[name=performerId]').val() || 0);

    if ($.isFunction($.fn.charCount)) {
      $(".js-text_limit").charCount({
        "css": "counter note-fieldheader"
      });
    }

    $('.ticket-limit').on('change', function () {
      const enableLimit = $(this).is(':checked');
      const ticketAmountField = $('.ticket-amount-field');

      if (enableLimit) {
        ticketAmountField.find('input').val(100);
        ticketAmountField.removeClass('hidden');
      } else {
        ticketAmountField.addClass('hidden');
        ticketAmountField.find('input').val(-1);
      }
    });

    _this.updateSummary();

    $(document).trigger('onEventsInited', _this);

    debug('init finished');

  }());

  if ($.fn.hasOwnProperty('dropzone')) {
    $(".ss_event-image_uploader").dropzone({
      url: showControllerUrl,
      acceptedFiles: "image/jpeg, image/jpg, image/png",
      maxFiles: 1,
      createImageThumbnails: false,
      paramName: "image",
      sending: function (file, xhr, formData) {
        const parent = $(this.element).closest(".ss_event");
        formData.append("cmd", "upload");
        formData.append("id", parent.data("event-id"));
        $(".loader", this.element).removeClass("hidden");
      },
      complete: function (file) {
        $(".loader", this.element).addClass("hidden");
      },
      success: function (file, response) {
        // const resp = JSON.parse(response);
        const resp = response;
        if (resp.code === 'OK') {
          location.reload();
        } else {
          swalError(resp.message);
        }
        this.removeAllFiles();
      },
      addedfile: function (file) {
        $(".dz-preview", this.element).remove();
        $(this.element).append(
          '<div class="dz-preview"><div class="dz-error-message"><span data-dz-errormessage=""></span></div></div>'
        );
      },
      error: function (file, errorMessage) {
        $(".dz-error-message", this.element).text(errorMessage);
      },
      previewTemplate: '<div style="display:none"></div>'
    });
  }

  $('.form-submit', editModal).on('click', function () {
    editModal.find('form').submit();
  });

  const result_block = $(".form-result", editModal).html("");

  const showError = function(form, html_code) {
    result_block.html('<p class="error">' + html_code + '</p>');

    const elements = form.find('input, select, textarea');
    elements.each(function() {
      if (!$(this) || $(this).val() === '') {
        $(this).addClass('required');
      }
    });
    setTimeout(function() {
      if (elements && elements.length > 0) {
        elements.each(function () {
          $(this).removeClass('required');
        });
      }
    }, 3000);
  };

  $("input[name=datetime], *[name=duration_min]").on('change', function () {
    _this.updateSummary();
  });

  this.validateDate = function() {
    return null;
  };

  editModal.find('form').on('submit', function (e) {
    e.preventDefault();

    const errors = [];
    const formData = new FormData(this);

    let dateValidationError = _this.validateDate();
    if (dateValidationError) {
      errors.push(dateValidationError);
    }

    $('.form-submit', $(this).parent()).prop('disabled', true);

    const priceElement = $('input[name=ticket-price]:visible');
    const ticketElement = $('input[name=tickets-amount]:visible');

    if (priceElement.length > 0) {
      const priceVal = priceElement.val();
      if (isNaN(priceVal) || priceVal < 0) {
        errors.push(I18n.t("Please specify correct ticket price"));
      }
    }
    if (ticketElement.length > 0) {
      const ticketPrice = ticketElement.val();
      if (isNaN(ticketPrice) || ticketPrice <= 0) {
        errors.push(I18n.t("Please specify correct tickets amount"));
      }
    }

    if (errors.length > 0) {
      showError($(this), errors[0]);
      $('.form-submit', $(this).parent()).prop('disabled', false);
      return false;
    }

    formData.set('datetime', $('#datepicker').val() + ' ' + $('#time-picker').val());

    $(document).trigger('onEventsSubmit', formData);

    $.ajax({
      url: $(this).attr('action'),
      data: formData,
      cache : false,
      processData: false,
      contentType : false,
      type: 'POST',
      success: data => {
        if (data.code === 'OK') {

          let text;
          if (action === actions.request_appointment) {
            text = I18n.t('Request sent!');
          } else if (action === actions.create_show) {
            text = I18n.t('Event created!');
          } else if (action === actions.edit_event) {
            text = I18n.t('Event edited!');
          }

          swal({
            title: text,
            type: 'success',
            showConfirmButton: false,
            confirmButtonColor: '#c0341d'
          });

          setTimeout(() => window.location.reload(), 2000);

        } else {
          swalError(data.message);
          $('.form-submit', $(this).parent()).prop('disabled', false);
        }
      },
      error: () => {
        swalError(I18n.t('Internal error! Please try later'));
        $('.form-submit', $(this).parent()).prop('disabled', false);
      }
    });
  });

  function hideModal(el) {
    if (el === undefined) {
      el = $('#event-modal');
    }

    el.addClass('hidden');
    $('.error', el).remove();

    updateQueryStringParameter(location.href, 'id', '')
  }

  function clearModal(el) {
    if (el === undefined) {
      el = $('#event-modal');
    }

    el.find('input, textarea').val('');
  }

  function showModal(el) {
    if (el === undefined) {
      el = $('#event-modal');
    }

    el.removeClass('hidden');
  }

  $('.close-modal').on('click', function (e) {
    e.preventDefault();
    hideModal($('.popup'));
  });

  $('.js-create_event').on('click', function (e) {
    clearModal();
    setAction(actions.create_show);
    showModal();
  });

  $('.js-edit_event').on('click', function() {
    setAction(actions.edit_event);

    const form = editModal.find('form')[0];
    const item = $(this).closest('.ss_event');

    $.ajax({
      url: commonControllerUrl,
      cache: false,
      data: {
        cmd: 'get-info',
        id: item.data('event-id')
      },
      success: (data) => {
        let event = data.message;
        if (data.code !== 'OK') {
          swalError(data.message);
          return;
        }

        $('.form-submit', editModal).text(I18n.t('Save'));

        if (!event.hasOwnProperty('customData')) {
          return;
        }

        const custom = event.customData.data;
        const startDateTime = new Date(event.startTime.seconds * 1000);

        const hours = startDateTime.getHours();
        const minutes = startDateTime.getMinutes();

        let H;
        if (usedAMPM) {
          H = hours % 12 || 12;
        } else {
          H = hours;
        }
        H = formatTimeUnit(H);

        let m = formatTimeUnit(minutes);
        let a = hours < 12 ? 'AM' : 'PM';

        const time =  usedAMPM ? `${H}:${m} ${a}` : `${H}:${m}`;

        $('#time-picker').val(time);
        $('#datepicker').datepicker('setDate', startDateTime);

        $(document).trigger('onEventsEdit', event);

        if (useCalendarTime) {
          _this.initDatetimepicker(event.performerId);
        }

        if (!form) return;

        if (form.elements.hasOwnProperty('title')) {
          form.elements['title'].value = custom['title'];
        }
        if (form.elements.hasOwnProperty('description')) {
          form.elements['description'].value = custom['description'];
        }
        if (form.elements.hasOwnProperty('tickets-amount')) {
          form.elements['tickets-amount'].value = custom['ticketsAmount'];
        }
        if (form.elements.hasOwnProperty('ticket-price')) {
          form.elements['ticket-price'].value = custom['ticketPrice'];
        }
        if (form.elements.hasOwnProperty('duration_min')) {
          form.elements['duration_min'].value = custom['duration'];
        }
        if (form.elements.hasOwnProperty('datetime')) {
          form.elements['datetime'].value = custom['startTimeFormatted'].toLowerCase();
        }
        if (form.elements.hasOwnProperty('id')) {
          form.elements['id'].value = event['id'];
        }
      }
    });

    showModal();
  });

  $('.js-remove_event, .js-remove_appointment').on('click', function() {
    const isAppointment = $(this).hasClass('js-remove_appointment');
    const eventId = $(this).closest('.ss_event').data('event-id');
    const type = isAppointment ? I18n.t('appointment') : I18n.t('show');

    const data = {
      cmd: 'delete',
      id: eventId
    };

    swal({
      title: I18n.t('Are you sure?'),
      text: I18n.t("Are you sure you want to delete this {1}?", [type]) + "\n"
          + I18n.t("This action can not be undone.") + "\n"
          + I18n.t('The credits for the tickets will be returned to the {1}\'s account.', [I18n.t('customer')]),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (!result.hasOwnProperty('value') || !result.value) {
        return false;
      }

      $.post(isAppointment ? appointmentsControllerUrl : showControllerUrl, data, null, 'json')
          .done(data => {
            if (data.code === 'OK') {
              swal({
                title: I18n.t('Deleted'),
                type: 'success',
                showConfirmButton: false
              });
              setTimeout(() => {location.reload()}, 3000);
            } else {
              swalError(data.message);
            }
          });
    });

  });

  function askReason() {
    return swal({
      title: I18n.t("Please enter reason"),
      input: "text",
      inputPlaceholder: I18n.t("This text visible for {1}", [I18n.t("customer")]),
      showCancelButton: true,
      inputValidator: function(value) {
        return !value && I18n.t("You need to write something!")
      }
    });
  }

  function updateStatus(id, status, reason) {
    const data = {
      cmd: 'update-status',
      action: status,
      id: id,
      reason: reason
    };

    $.post(showControllerUrl, data, null, 'json')
        .done(data => {

          if (data.code === 'OK') {
            window.location.reload();
          } else {
            swalError(data.message);
          }
        }).error(() => console.log('error'));
  }

  $('.js-approve_event').on('click', function () {
    const item = $(this).closest('.ss_event');
    updateStatus(item.data('event-id'), 'approve');
  });

  $('.js-decline_event').on('click', function () {
    const item = $(this).closest('.ss_event');

    askReason().then((reason) => {
      if (reason.value) {
        updateStatus(item.data('event-id'), 'decline', reason.value);
      }
    });
  });

  function updateAppointment(cmd, id, reason) {
    const data = {
      cmd: cmd,
      id: id,
      reason: reason
    };

    $.post(appointmentsControllerUrl, data, null, 'json').done(data => {
      if (data.code === 'OK') {
        window.location.reload();
      } else {
        swalError(data.message);
      }
    });
  }

  $('.js-approve_appointment').on('click', function () {
    const item = $(this).closest('.ss_event');
    updateAppointment('accept-request', item.data('event-id'));
  });

  $('.js-decline_appointment').on('click', function () {
    const item = $(this).closest('.ss_event');

    askReason().then((reason) => {
      if (reason.value) {
        updateAppointment('decline-request', item.data('event-id'), reason.value);
      }
    });
  });

  $('.js-request_appointment').on('click', function () {
    setAction(actions.request_appointment);
    showModal();
  });

  $('.js-event_buy_ticket').on('click', function () {
    const eventId = $(this).closest('*[data-event-id]').data('event-id');
    $(document).trigger('onTicketShow', [{eventId}]);
  });

  $(document).on('onTicketShow', (jqev, ticketData) => {
    showTicket(ticketData)
  })

  function showTicket(ticketData) {
    setAction(actions.buy_ticket);

    const {eventId, freeTicketKey, discountPrice} = ticketData
    const form = $('#ticket-form')
    const data = {
      cmd: 'get-info',
      id: eventId
    }

    $.post(commonControllerUrl, data)
        .done(data => {
          if (data.code === 'OK') {
            const event = data.message;
            const {
                title,
                startTimeFormatted,
                duration,
                description,
                image,
                ticketPrice
            } = event.customData.data

            const price = ticketPrice > 0 ? ticketPrice : I18n.t('Free')

            form.find('input[name=id]').val(event.id)
            form.find('input[name=k]').val(freeTicketKey)
            $('#modal-header').text(title)
            $('#event-date').text(startTimeFormatted)
            $('#event-duration').text(duration)

            let ticketPriceText = I18n.t('Ticket price') + ': '
            if (!isNaN(parseInt(discountPrice)) && ticketPrice > 0) {
              if (discountPrice === 0) {
                ticketPriceText += `<s>${price}</s> Free!`
              } else {
                ticketPriceText += `<s>${price}</s> ${discountPrice}`
              }
            } else {
              ticketPriceText += price
            }


            $('#event-price').html(ticketPriceText)
            $('#event-description').text(description)

            $('#event-preview').css({
              'background-image':
                  'url(' + (image ? image : "/plugins/shows/images/ss_noimage.jpg") + ')'
            });

          } else {
            swalError(data.message);
          }
        });

    showModal(ticketModal);
    updateQueryStringParameter(location.href, 'id', eventId)
  }

  function updateQueryStringParameter(uri, key, value) {
    if (!useLinks) {
      return;
    }

    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';

    let newURI;
    if (uri.match(re)) {
      newURI = uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      newURI = uri + separator + key + '=' + value;
    }

    history.pushState(null, null, newURI);
  }

  $(document).on('keyup', function (e) {
    if (e.key === 'Escape') {
      hideModal($('.popup'));
    }
  });

  $(document).on('click', '.popup__overlay', function (e) {
    if (e.target !== this) {
      return false;
    }

    hideModal($('.popup'));
  });

  $('#ticket-form form').on('submit', function (e) {
    const formData = new FormData(this);
    const errorElement = $(this).parent()
        .find('.form-result')
        .html('');

    e.preventDefault();
    $('#buy-ticket').prop('disabled', true);

    $.ajax({
      url: $(this).attr('action'),
      data: formData,
      cache : false,
      processData: false,
      contentType : false,
      type: 'POST',
      success: data => {

        if (data.code === 'OK') {

          swal({
            title: 'Ticket purchased!',
            type: 'success',
            showConfirmButton: false
          });

        } else if (data.code === 'NO_PERMISSION') {

          location.href = Config.exec + '/login?login_redirect_url=' + encodeURIComponent(location.href);
          return true;

        } else {

          errorElement.html('<div class="ui error message">' + data.message + '</div>');
          $('#buy-ticket').prop('disabled', false);

        }

        setTimeout(() => location.reload(), 3 * 1000);
      },
      error: () => {
        swalError(I18n.t('Internal Error!'));
        $('#buy-ticket').prop('disabled', false);
      }
    });
    return false;
  });

  $('#buy-ticket').on('click', function () {
    const form = $('#ticket-form form');
    form.submit();
  });

  $('.event-copy-link').on('click', function (e) {
    e.preventDefault()

    const text = $(this).text()

    navigator.clipboard.writeText($(this).attr('href'))

    $(this).text(`(${I18n.t('Copied!')})`)
    setTimeout(() => {
      $(this).text(text)
    }, 2000)
  })

  function swalError(title, text) {
    return swal({
      title: title,
      text: text,
      type: 'error'
    });
  }

  function formatTimeUnit(timeUnit) {
    if (timeUnit.toString().length === 1) {
      return '0' + timeUnit;
    }
    return timeUnit;
  }

  function debug(msg) {
    if (isDebug === true) {
      const now = new Date().toLocaleTimeString();
      console.log(`[SCHEDULED SHOWS][${now}]`, msg);
    }
  }

}
